import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'
import { LanguageCode } from 'modules/MultiLang'

const SET_LANGUAGE_ACTION = '[STATE] set language'

export interface SetLanguageAction extends Action<typeof SET_LANGUAGE_ACTION> {
  payload: {
    selectedLanguage: LanguageCode
  }
}

export const isSetLanguageAction = (x: any): x is SetLanguageAction => 
  isAction(x) && x.type === SET_LANGUAGE_ACTION

export const createSetLanguageAction = (selectedLanguage: LanguageCode): SetLanguageAction => ({ 
  type: SET_LANGUAGE_ACTION,
  payload: {
    selectedLanguage
  }
})
