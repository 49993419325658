import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const SET_FEEDBACK_IN_TRANSIT_ACTION = '[STATE] set feedback in transit'

export interface SetFeedbackInTransitAction extends Action<typeof SET_FEEDBACK_IN_TRANSIT_ACTION> {
  payload: {
    inTransit: boolean
  }
}

export const isSetFeedbackInTransitAction = (x: any): x is SetFeedbackInTransitAction =>
  isAction(x) && x.type === SET_FEEDBACK_IN_TRANSIT_ACTION

export const createSetFeedbackInTransitAction = (inTransit: boolean): SetFeedbackInTransitAction => ({
  type: SET_FEEDBACK_IN_TRANSIT_ACTION,
  payload: {
    inTransit
  }
})
