import { Middleware } from 'redux'
import { AppState } from 'state/store'
import { isLanguageToggleAction } from 'components/Header'
import { LanguageCode } from 'modules/MultiLang'
import { createSetLanguageAction } from 'state/language'

const languageMiddleware: Middleware<{}, AppState> = middlewareAPI => next => action => {

  // First let the action be seen by all middleware in the chain
  const returnedAction = next(action)
  
  // Only after that (potentially) dispatch the new action
  if (isLanguageToggleAction(action)) {

    const currentLanguage = middlewareAPI.getState().language.selectedLanguage

    const nextLanguage = currentLanguage === LanguageCode.de ? LanguageCode.en : LanguageCode.de

    middlewareAPI.dispatch(createSetLanguageAction(nextLanguage))
  }
  // This way all middleware had the chance to react to the original action (e. g. for logging) before the next action
  // got dispatched -- the order of actions can be preserved.

  // Preserve the middleware chain
  return returnedAction
}

export default languageMiddleware
