export interface FeedbackState {
  choices: Array<number|undefined>,
  text: string|undefined,
  comment: string|undefined,
  consent: boolean,
  inTransit: boolean,
  sent: boolean,
}

export function isFeedbackComplete(state: FeedbackState) {
  return !state.choices.some(answer => answer === undefined)
}

export function isFeedbackInTransit(state: FeedbackState) {
  return state.inTransit
}

export function isFeedbackConsentGiven(state: FeedbackState) {
  return state.consent
}

export function isFeedbackSent(state: FeedbackState) {
  return state.sent
}

export function getFeedbackChoice(state: FeedbackState, questionIndex: number) {
  return state.choices[questionIndex]
}

export function getFeedbackText(state: FeedbackState) {
  return state.text
}
