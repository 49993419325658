import produce from 'immer'
import { MenuState } from './MenuState'
import { isMenuOpenAction } from './actions/SetMenuOpenAction'

const initialMenuState: MenuState = {
  isOpen: false
}

const menuReducer = produce((draft: MenuState, action: any): void => {
  if (isMenuOpenAction(action)) {
    draft.isOpen = action.payload.isOpen
  }
}, initialMenuState)

export default menuReducer
