import produce from 'immer'
import { FeedbackState } from "./FeedbackState"
import { feedbackConfig } from 'config/AppConfig'
import { isSetFeedbackChoiceAction } from "./actions/SetFeedbackChoiceAction"
import { isSetFeedbackTextAction } from "./actions/SetFeedbackTextAction"
import { isSetFeedbackCommentAction } from "./actions/SetFeedbackCommentAction"
import { isSetFeedbackConsentAction } from "./actions/SetFeedbackConsentAction"
import { isSetFeedbackInTransitAction } from "./actions/SetFeedbackInTransitAction"
import { isSetFeedbackSentAction } from "./actions/SetFeedbackSentAction"

const initialFeedbackState: FeedbackState = {
  choices: feedbackConfig.questions.map(() => undefined),
  text: undefined,
  comment: undefined,
  consent: false,
  inTransit: false,
  sent: false,
}

export const feedbackReducer = produce((draft: FeedbackState, action: any): void => {

  if (isSetFeedbackChoiceAction(action)) {
    // TODO: Check validity?
    draft.choices[action.payload.questionId] = action.payload.choice

  } else if (isSetFeedbackTextAction(action)) {
    draft.text = action.payload.text

  } else if (isSetFeedbackCommentAction(action)) {
    draft.comment = action.payload.comment

  } else if (isSetFeedbackConsentAction(action)) {
    draft.consent = action.payload.consent

  } else if (isSetFeedbackInTransitAction(action)) {
    draft.inTransit = action.payload.inTransit

  } else if (isSetFeedbackSentAction(action)) {
    draft.sent = action.payload.sent

  }
}, initialFeedbackState)
