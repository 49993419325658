import { mean, zip } from "ramda"

// TODO Handle empty list by using a Maybe type as return type instead of returning NaN?

function choiceToPercent(index: number, count: number): number {
  return index / (count - 1) * 100
}

function choicesToPercents(indexes: number[], counts: number[]): number[] {
  return zip(indexes, counts).map(([index, count]) => choiceToPercent(index, count))
}

function meanOfPercentsOfChoices([indexes, counts]: [number[], number[]]): number {
  return mean(choicesToPercents(indexes, counts))
}

/**
 * Calculates the percentage of all choices in relation to their choice counts and then calculates their mean per group.
 * 
 * @param choiceIndexGroups 
 * @param choiceCountGroups 
 */
function choiceGroupsToMeanOfPercents(
  choiceIndexGroups: number[][], 
  choiceCountGroups: number[][]
): number[] {
  return zip(choiceIndexGroups, choiceCountGroups).map(meanOfPercentsOfChoices)
}

export default choiceGroupsToMeanOfPercents
