import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const OUTSIDE_OF_OPEN_MENU_CLICK_ACTION = '[UI] outside of open menu click'

export interface OutsideOfOpenMenuClickAction extends Action<typeof OUTSIDE_OF_OPEN_MENU_CLICK_ACTION> {}

export const isOutsideOfOpenMenuClickAction = (x: any): x is OutsideOfOpenMenuClickAction => 
  isAction(x) && x.type === OUTSIDE_OF_OPEN_MENU_CLICK_ACTION

export const createOutsideOfOpenMenuClickAction = (): OutsideOfOpenMenuClickAction => ({
  type: OUTSIDE_OF_OPEN_MENU_CLICK_ACTION
})
