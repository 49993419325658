import React from 'react'
import Button from '@material-ui/core/Button'
import { store } from 'state/store'
import { createLanguageToggleAction } from './actions/LanguageToggleAction'
import { LanguageCode } from 'modules/MultiLang'
import { LanguageToggleConfig } from './LanguageToggleConfig'
import { languageToggle } from 'config/AppConfig'

const config: LanguageToggleConfig = languageToggle

function LanguageToggle(props: {
  lang: LanguageCode, 
}) {
  return (
    <Button onClick={() => store.dispatch(createLanguageToggleAction())}>
      {config.label[props.lang]}
    </Button>
  )
}

export default LanguageToggle
