import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const SET_VIEW_ACTION = '[STATE] set view'

export interface SetViewAction extends Action<typeof SET_VIEW_ACTION> {
  payload: {
    selectedViewIndex: number
  },
}

export const isSetViewAction = ( x: any ): x is SetViewAction => 
  isAction(x) && x.type === SET_VIEW_ACTION

export const createSetViewAction = (selectedViewIndex: number): SetViewAction => ({
  type: SET_VIEW_ACTION,
  payload: {
    selectedViewIndex
  },
})
