export enum LanguageCode {
  de = 'de', 
  en = 'en',
};

export const defaultLanguage = LanguageCode.de

export type MultiLangObject<T> = {
  [key in LanguageCode]: T
}

export type MultiLangString = MultiLangObject<string>;

// const isMultiLangObject = <T>(x: any): x is MultiLangObject<T> => {
//   if (typeof x !== 'object') {
//     return false;
//   }
//   const props = Object.getOwnPropertyNames(x);
//   const validLangCodes = Object.keys(LanguageCode);
//   return validLangCodes.map(code => props.includes(code)).reduce((a, b) => a && b);
// }

// export const unwrap = (x: any, lang: LanguageCode): any => {
//   if (isMultiLangObject(x)) {
//     return x[lang];
//   }
//   if (Array.isArray(x)) {
//     return x.map(item => unwrap(item, lang));
//   }
//   if (typeof x === 'object') {
//     return Object.fromEntries(Object.entries(x).map(([key, value]) => [key, unwrap(value, lang)]))
//   }
//   return x;
// }
