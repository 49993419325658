import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const CHANGE_FEEDBACK_COMMENT_ACTION = '[UI] change feedback comment'

export interface ChangeFeedbackCommentAction extends Action<typeof CHANGE_FEEDBACK_COMMENT_ACTION> {
  payload: {
    newText: string
  }
}

export const isChangeFeedbackCommentAction = (x: any): x is ChangeFeedbackCommentAction => 
  isAction(x) && x.type === CHANGE_FEEDBACK_COMMENT_ACTION

export const createChangeFeedbackCommentAction = (newText: string): ChangeFeedbackCommentAction => ({
  type: CHANGE_FEEDBACK_COMMENT_ACTION,
  payload: {
    newText
  }
})
