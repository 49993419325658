import { Action } from "redux"
import { isAction } from "modules/ActionTypeGuard"

const SWIPE_VIEW_ACTION = '[UI] swipe view'

export interface SwipeViewAction extends Action<typeof SWIPE_VIEW_ACTION> {
  payload: {
    nextIndex: number
  }
}

export const isSwipeViewAction = (x: any): x is SwipeViewAction => 
  isAction(x) && x.type === SWIPE_VIEW_ACTION

export const createSwipeViewAction = (nextIndex: number): SwipeViewAction => ({
  type: SWIPE_VIEW_ACTION,
  payload: {
    nextIndex
  }
})
