import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const CHANGE_FEEDBACK_TEXT_ACTION = '[UI] change feedback text'

export interface ChangeFeedbackTextAction extends Action<typeof CHANGE_FEEDBACK_TEXT_ACTION> {
  payload: {
    newText: string
  }
}

export const isChangeFeedbackTextAction = (x: any): x is ChangeFeedbackTextAction => 
  isAction(x) && x.type === CHANGE_FEEDBACK_TEXT_ACTION

export const createChangeFeedbackTextAction = (newText: string): ChangeFeedbackTextAction => ({
  type: CHANGE_FEEDBACK_TEXT_ACTION,
  payload: {
    newText
  }
})
