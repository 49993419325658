/**
 * Stellt die gesamten statischen Daten für die App zur Verfügung. Diese sind in der zentralen config.json abgelegt und
 * werden von diesem Modul in der Form aufbereitet und zur Verfügung gestellt, wie die Komponenten/Module sie benötigen.
 * 
 * Begründung für diese Lösung: Eine zentrale App-Config-Datei ist gut versendbar und für Nicht-Entwickler bearbeitbar.
 */

import configJSON from 'config.json';
import { pushChoices, IdeaEvaluationConfig } from './IdeaEvaluationConfig';
import { EmailTemplateConfig } from 'components/EmailTemplate';
import { FeedbackConfig } from 'components/Feedback';
import { IdeaAnalysisConfig } from 'components/IdeaAnalysis';
import { MultipleChoiceQuestionGroup } from 'components/App';
import { IdeaProfileConfig } from 'components/IdeaProfile';
import { InfoPopupConfig } from 'components/InfoPopup';
import { LanguageToggleConfig } from 'components/LanguageToggle';
import { MainMenuConfig } from 'components/MainMenu';
import { NavBarConfig } from 'components/NavBar';
import { MultiLangString } from 'modules/MultiLang';
import { sum } from 'ramda';

interface AppConfig {
  languageToggle: LanguageToggleConfig,
  mainMenu: MainMenuConfig,
  navBar: NavBarConfig,
  ideaProfile: IdeaProfileConfig,
  infoPopup: InfoPopupConfig,
  ideaEvaluation: IdeaEvaluationConfig<MultiLangString>,
  ideaAnalysis: IdeaAnalysisConfig,
  emailTemplate: EmailTemplateConfig,
  feedback: FeedbackConfig,
}

const config: AppConfig = configJSON

export const languageToggle: LanguageToggleConfig = config.languageToggle
export const mainMenu: MainMenuConfig = config.mainMenu
export const navBar: NavBarConfig = config.navBar
export const ideaProfile: IdeaProfileConfig = config.ideaProfile
export const infoPopup: InfoPopupConfig = config.infoPopup

// export const ideaEvaluation: IdeaEvaluationConfig<MultiLangString> = config.ideaEvaluation
export const questionGroups: MultipleChoiceQuestionGroup[] = pushChoices(config.ideaEvaluation.questionGroups)
export const questionGroupLengths = questionGroups.map(group => group.questions.length)
export const questionCount = sum(questionGroupLengths)
export const choiceCounts = questionGroups.map(group => group.questions.map(question => question.choices.length))

// 1 view for the idea profile, n views for the questions, 1 view for the analysis and 1 view for the feedback form
export const viewCount = 1 + questionCount + 1 + 1
// TODO This depends on structure of UI

// -1 because it's the second to last view and another -1 because of count to index conversion
export const analysisViewIndex = (viewCount - 1) - 1
// TODO This also depends on structure of UI

export const ideaAnalysis: IdeaAnalysisConfig = config.ideaAnalysis
export const emailTemplate: EmailTemplateConfig = config.emailTemplate
export const feedbackConfig: FeedbackConfig = config.feedback

export const feedbackApiUrl = config.feedback.apiURL
