import { Middleware } from 'redux'
import { AppState } from 'state/store'
import { isSwipeViewAction } from 'components/Content'
import { isNextButtonClickAction, isBackButtonClickAction } from 'components/NavBar'
import { createSetInfoPopupClosedAction, createSetViewAction } from 'state/navigation'
import { isCloseInfoPopupAction } from 'components/InfoPopup'
import { isLogoClickAction } from 'components/Header'
import { isNextViewAction } from './actions/NextViewAction'

const navigationMiddleware: Middleware<{}, AppState> = middlewareAPI => next => action => {
  const returnedAction = next(action)
  
  const currentViewIndex = middlewareAPI.getState().navigation.selectedViewIndex

  if (isLogoClickAction(action)) {
    
    middlewareAPI.dispatch(createSetViewAction(0))

  } else if (isNextButtonClickAction(action) || isNextViewAction(action)) {

    // TODO: prüfen, ob Weiternavigation eigentlich erlaubt ist
    // TODO: Muss das hier überhaupt auch geprüft werden? Wird ja auch (wahrscheinlich) in NavigationState geprüft.
    // TODO: Was soll eigentlich bei solchen Fehlern passieren?

    middlewareAPI.dispatch(createSetViewAction(currentViewIndex + 1))

  } else if (isBackButtonClickAction(action) && currentViewIndex > 0) {

    middlewareAPI.dispatch(createSetViewAction(currentViewIndex - 1))

  } else if (isSwipeViewAction(action)) {

    // TODO: prüfen, ob Navigationsziel eigentlich erlaubt ist

    middlewareAPI.dispatch(createSetViewAction(action.payload.nextIndex))

  } else if (isCloseInfoPopupAction(action)) {

    middlewareAPI.dispatch(createSetInfoPopupClosedAction(true))

  }

  return returnedAction
}

export default navigationMiddleware
