import React from 'react'
import { store } from 'state/store'
import { createIdeaEvaluationChoiceAction } from './actions/IdeaEvaluationChoiceAction'
import MultipleChoiceQuestion from 'components/MultipleChoiceQuestion'
import { LanguageCode } from 'modules/MultiLang'
import ContentContainer from 'components/ContentContainer'
import { questionGroups } from 'config/AppConfig'

function IdeaEvaluationQuestion(props: {
  questionGroupIndex: number,
  questionIndex: number,
  selectedChoiceIndex: number|undefined,
  lang: LanguageCode,
}) {
  const questionGroup = questionGroups[props.questionGroupIndex]
  const groupTitle = questionGroup.title[props.lang]
  const groupSize = questionGroup.questions.length

  const question = questionGroup.questions[props.questionIndex]
  const questionText = question.question[props.lang]

  const choices = question.choices.map(multiLangChoice => multiLangChoice[props.lang])

  return (
    <ContentContainer>
      <h2>
        {groupTitle}
        {' '}
        <span className="progress-indication">
          ( {props.questionIndex + 1} / {groupSize} )
        </span>
      </h2>
      <MultipleChoiceQuestion
        question={questionText}
        choices={choices}
        selectedChoice={props.selectedChoiceIndex}
        selectionHandler={(event) => store.dispatch(
          createIdeaEvaluationChoiceAction(
            props.questionGroupIndex, 
            props.questionIndex, 
            parseInt(event.target.value)
          )
        )}
      />
    </ContentContainer>
  )
}

export default IdeaEvaluationQuestion
