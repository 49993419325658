import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const CLOSE_INFO_POPUP_ACTION = '[UI] close info popup'

export interface CloseInfoPopupAction extends Action<typeof CLOSE_INFO_POPUP_ACTION> {}

export const isCloseInfoPopupAction = (x: any): x is CloseInfoPopupAction => 
  isAction(x) && x.type === CLOSE_INFO_POPUP_ACTION

export const createCloseInfoPopupAction = (): CloseInfoPopupAction => ({
  type: CLOSE_INFO_POPUP_ACTION,
})
