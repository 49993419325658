import produce from 'immer'
import { IdeaEvaluationState } from './IdeaEvaluationState'
import { isSetIdeaEvaluationChoiceAction } from './actions/SetIdeaEvaluationChoiceAction'
import { questionGroups, questionCount, choiceCounts, questionGroupLengths } from 'config/AppConfig';
import { zip } from 'ramda';
import splitIntoSlices from 'modules/splitIntoSlices';

function urlToState(url: string): number[][] | null {

  const urlObj = new URL(url);
  const choicesString = urlObj.searchParams.get('choices');
  if (choicesString === null) {
    return null
  }

  // Split the string of choices into an array of single characters
  const choiceChars = choicesString.split('');
  // TODO: This does not work when a multiple choice question has 11 or more choices!

  // parseInt returns NaN for not parseable input
  const choiceValues = choiceChars.map(char => parseInt(char));
  // Need to wrap parseInt because of it's optional second argument, which is populated with the current index by map
  
  if (choiceValues.length !== questionCount) {
    // The URL does not have exactly as many choice entries as the app has questions
    return null;
  }

  // TODO Check if the choice values do fit in the allowed range for the questions
  const validValues = zip(choiceValues, choiceCounts.flat()).every(([val, count]) => val < count)
  if (!validValues) {
    return null
  }

  const choicesState = splitIntoSlices(choiceValues, questionGroupLengths);

  return choicesState;
}

const choicesFromURL = urlToState(window.location.href);

// Used by the UI to determine initial view (jumps directly to analysis if state has been passed to the app via the URL)
export const didChoicesComeFromURL = choicesFromURL !== null

const initialIdeaEvaluationState = didChoicesComeFromURL ? choicesFromURL : createInitialState()

function createInitialState(): IdeaEvaluationState {
  return questionGroups.map(questionGroup =>
    questionGroup.questions.map(() => undefined)
  )
}

const ideaEvaluationReducer = produce((draft: IdeaEvaluationState, action: any): void => {
  if (isSetIdeaEvaluationChoiceAction(action)) {
    draft[action.payload.groupId][action.payload.questionId] = action.payload.choice
  }
}, initialIdeaEvaluationState)

export default ideaEvaluationReducer
