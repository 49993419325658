import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const IDEA_EVALUATION_CHOICE_ACTION = '[UI] idea evaluation choice'

export interface IdeaEvaluationChoiceAction extends Action<typeof IDEA_EVALUATION_CHOICE_ACTION> {
  payload: {
    questionGroupId: number,
    questionId: number,
    selectedChoiceId: number
  }
}

export const isIdeaEvaluationChoiceAction = (x: any): x is IdeaEvaluationChoiceAction => 
  isAction(x) && x.type === IDEA_EVALUATION_CHOICE_ACTION

export const createIdeaEvaluationChoiceAction = (
  questionGroupId: number,
  questionId: number,
  selectedChoiceId: number
): IdeaEvaluationChoiceAction => ({
  type: IDEA_EVALUATION_CHOICE_ACTION,
  payload: {
    questionGroupId,
    questionId,
    selectedChoiceId,
  }
})
