import { Middleware } from 'redux'
import { isIdeaEvaluationChoiceAction } from 'components/IdeaEvaluationQuestion';
import {
  createSetIdeaEvaluationChoiceAction,
  isQuestionAnswered
} from 'state/ideaEvaluation'
import { createNextViewAction } from 'middleware/navigationMiddleware';
import { AppState } from 'state/store';

const ideaEvaluationMiddleware: Middleware<{}, AppState> = middlewareAPI => next => action => {
  const returnedAction = next(action)
  
  if (isIdeaEvaluationChoiceAction(action)) {

    const isAlreadyAnswered = isQuestionAnswered(
      action.payload.questionGroupId, 
      action.payload.questionId,
      middlewareAPI.getState().ideaEvaluation
    );

    // Dispatch Action to update the state
    middlewareAPI.dispatch(createSetIdeaEvaluationChoiceAction(
      action.payload.questionGroupId,
      action.payload.questionId,
      action.payload.selectedChoiceId
    ))

    // If question was previously unanswered, initiate navigation to next view.
    if (!isAlreadyAnswered) {
      middlewareAPI.dispatch(createNextViewAction())
    }
  }
  
  return returnedAction
}

export default ideaEvaluationMiddleware
