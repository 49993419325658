import React from 'react'
import './Rating.css'

function Rating(props: {
  firstRatingTitle: string,
  secondRatingTitle: string,
  firstRatingValue: number,
  secondRatingValue: number,
}) {
  return (
    <div className="ratingContainer">
      <div className="firstRatingTitle">{props.firstRatingTitle}</div>
      <div className="firstRatingValue">{props.firstRatingValue}</div>
      <div className="secondRatingTitle">{props.secondRatingTitle}</div>
      <div className="secondRatingValue">{props.secondRatingValue}</div>
    </div>
  )
}

export default Rating
