import React from 'react';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import './InfoBox.css';
import { CardContent, Card } from '@material-ui/core';

function InfoBox(props: {
  info: string,
}) {
  return (
    <Card className="infoContainer">
      <CardContent className="infoContent">
        <div className="infoIconContainer">
          <InfoOutlinedIcon fontSize="large" />
        </div>
        <div className="infoTextContainer">
          <p>{props.info}</p>
        </div>
      </CardContent>
    </Card>
  );
}

export default InfoBox
