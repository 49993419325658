import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const SET_FEEDBACK_COMMENT_ACTION = '[STATE] set feedback comment'

export interface SetFeedbackCommentAction extends Action<typeof SET_FEEDBACK_COMMENT_ACTION> {
  payload: {
    comment: string
  },
}

export const isSetFeedbackCommentAction = (x: any): x is SetFeedbackCommentAction =>
  isAction(x) && x.type === SET_FEEDBACK_COMMENT_ACTION

export const createSetFeedbackCommentAction = (comment: string): SetFeedbackCommentAction => ({
  type: SET_FEEDBACK_COMMENT_ACTION,
  payload: {
    comment
  },
})
