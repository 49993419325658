import React from 'react'
import { store } from 'state/store'
import { IconButton, Menu, MenuItem, Link } from '@material-ui/core'
import MoreIcon from '@material-ui/icons/MoreVert'
import { createMenuButtonClickAction } from './actions/MenuButtonClickAction'
import { createOutsideOfOpenMenuClickAction } from './actions/OutsideOfOpenMenuClickAction'
import { LanguageCode } from 'modules/MultiLang'
import { MainMenuConfig } from './MainMenuConfig'
import { mainMenu } from 'config/AppConfig'

const config: MainMenuConfig = mainMenu

function MainMenu(props: {
  isMenuOpen: boolean,
  lang: LanguageCode,
}) {
  return (
    <>
      <IconButton id="menu-button" onClick={() => store.dispatch(createMenuButtonClickAction())}>
        <MoreIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={(element: Element) => {
          const anchorEl = document.getElementById('menu-button')
          if (anchorEl === null) {
            // This should never be null, because we use the id of the button (see above) to find the anchor
            // but to satisfy the TypeScript type system we return what we have: the argument
            return element
            // TODO What is this 'element'?
          }
          return anchorEl
        }}
        keepMounted
        open={props.isMenuOpen}
        onClose={() => store.dispatch(createOutsideOfOpenMenuClickAction())}
      >
        <MenuItem>
          <Link
            href={config.impressum.link[props.lang]}
            target="_blank"
            color="textPrimary"
          >
            {config.impressum.label[props.lang]}
          </Link>
        </MenuItem>
        <MenuItem>
          <Link
            href={config.privacyPolicy.link[props.lang]}
            target="_blank"
            color="textPrimary"
          >
            {config.privacyPolicy.label[props.lang]}
          </Link>
        </MenuItem>
      </Menu>
    </>
  )
}

export default MainMenu
