import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const SET_FEEDBACK_CONSENT_ACTION = '[STATE] set feedback consent'

export interface SetFeedbackConsentAction extends Action<typeof SET_FEEDBACK_CONSENT_ACTION> {
  payload: {
    consent: boolean
  }
}

export const isSetFeedbackConsentAction = (x: any): x is SetFeedbackConsentAction =>
  isAction(x) && x.type === SET_FEEDBACK_CONSENT_ACTION

export const createSetFeedbackConsentAction = (consent: boolean): SetFeedbackConsentAction => ({
  type: SET_FEEDBACK_CONSENT_ACTION,
  payload: {
    consent
  }
})
