import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const LOGO_CLICK_ACTION = '[UI] logo click'

export interface LogoClickAction extends Action<typeof LOGO_CLICK_ACTION> {}

export const isLogoClickAction = (x: any): x is LogoClickAction => 
  isAction(x) && x.type === LOGO_CLICK_ACTION

export const createLogoClickAction = (): LogoClickAction => ({
  type: LOGO_CLICK_ACTION
})
