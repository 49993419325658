import { Middleware } from 'redux'
import { AppState } from 'state/store'
import { isMenuButtonClickAction, isOutsideOfOpenMenuClickAction } from 'components/Header'
import { createSetMenuOpenAction } from 'state/menu'

const menuMiddleware: Middleware<{}, AppState> = middlewareAPI => next => action => {

  const returnedAction = next(action)
  
  if (isMenuButtonClickAction(action) && !middlewareAPI.getState().menu.isOpen) {
    middlewareAPI.dispatch(createSetMenuOpenAction(true))
  }

  if (isOutsideOfOpenMenuClickAction(action) && middlewareAPI.getState().menu.isOpen) {
    middlewareAPI.dispatch(createSetMenuOpenAction(false))
  }

  return returnedAction
}

export default menuMiddleware
