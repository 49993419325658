import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const SET_FEEDBACK_TEXT_ACTION = '[STATE] set feedback text'

export interface SetFeedbackTextAction extends Action<typeof SET_FEEDBACK_TEXT_ACTION> {
  payload: {
    text: string
  },
}

export const isSetFeedbackTextAction = (x: any): x is SetFeedbackTextAction =>
  isAction(x) && x.type === SET_FEEDBACK_TEXT_ACTION

export const createSetFeedbackTextAction = (text: string): SetFeedbackTextAction => ({
  type: SET_FEEDBACK_TEXT_ACTION,
  payload: {
    text
  },
})
