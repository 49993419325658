import React from "react"
import analyzeChoices from 'modules/Analysis'
import Chart from "components/Chart"
import { IdeaEvaluationState, isIdeaEvaluationCompleted } from "state/ideaEvaluation"
import ContentContainer from "components/ContentContainer"
import Rating from "components/Rating"
import './IdeaAnalysis.css'
import EmailTemplate from "components/EmailTemplate"
import { LanguageCode } from "modules/MultiLang"
import { choiceCounts, ideaAnalysis, questionGroups } from "config/AppConfig"
import { IdeaAnalysisConfig, SectorConfig } from "./IdeaAnalysisConfig"



const config: IdeaAnalysisConfig = ideaAnalysis



function IdeaAnalysis(props: {
  evaluationState: IdeaEvaluationState, // TODO Hier abgeschlossene Evaluation voraussetzen(?)
  lang: LanguageCode,
}) {
  const { evaluationState, lang } = props

  if (!isIdeaEvaluationCompleted(evaluationState)) {
    return (
      <ContentContainer heading={config.heading[lang]}>
        <p>Error: Idea evaluation incomplete!</p>
      </ContentContainer>
    )
  }

  // Here all entries of state are defined
  const finishedEvalState = evaluationState as number[][]

  const questionGroupTitles = questionGroups.map(group => group.title[lang])

  const [y, x] = analyzeChoices(finishedEvalState, choiceCounts)

  const ideaURL = 'https://app.ideenbewertung.de/?choices=' + finishedEvalState.flat().join('')
  // TODO: Put currently hardcoded url into config!
  
  const boundary = config.chartSectors.boundary
  let sector: SectorConfig
  if (x <= boundary.x && y > boundary.y) {
    sector = config.chartSectors.topLeft
  } else if (x > boundary.x && y > boundary.y) {
    sector = config.chartSectors.topRight
  } else if (x <= boundary.x && y <= boundary.y) {
    sector = config.chartSectors.bottomLeft
  } else {
    sector = config.chartSectors.bottomRight
  }
  // TODO Make this functional(?)
  
  return (
    <ContentContainer
      heading={config.heading[lang]}
      info={config.info[lang]}
    >
      <Rating
        firstRatingTitle={questionGroupTitles[0]}
        firstRatingValue={Math.round(y)}
        secondRatingTitle={questionGroupTitles[1]}
        secondRatingValue={Math.round(x)}
      />
      <div className="chartContainer">
        <Chart 
          value={{ x, y }} 
          axisLabels={{ x: questionGroupTitles[1], y: questionGroupTitles[0] }} 
          sectorBoundary={boundary}
          sectorNames={{ // TODO: unschön?
            topLeft: config.chartSectors.topLeft.name[lang],
            topRight: config.chartSectors.topRight.name[lang],
            bottomLeft: config.chartSectors.bottomLeft.name[lang],
            bottomRight: config.chartSectors.bottomRight.name[lang],
          }}
        />
      </div>
      <h3>{config.explanationHeading[lang]}</h3>
      {sector.paragraphs.map((content, i) => (
        <p key={i}>{content[lang]}</p>
      ))}
      <EmailTemplate
        x={Math.round(x)}
        y={Math.round(y)}
        url={ideaURL}
        lang={lang}
      />
    </ContentContainer>
  )
}

export default IdeaAnalysis
