import React from "react";
import ContentContainer from "components/ContentContainer";
import { TextField } from "@material-ui/core";
import './IdeaProfile.css';
import { LanguageCode } from "modules/MultiLang";
import { IdeaProfileConfig } from "./IdeaProfileConfig";
import { ideaProfile } from "config/AppConfig";

const config: IdeaProfileConfig = ideaProfile

function IdeaProfile(props: { lang: LanguageCode }) {
  return (
    <ContentContainer
      heading={config.heading[props.lang]}
      info={config.info[props.lang]}
    >
      <div className="ideaTitle">
        <TextField 
          // multiline
          fullWidth
          variant="outlined"
          label={config.titleFieldLabel[props.lang]}
        />
      </div>
      <div className="ideaDescription">
        <TextField 
          multiline
          rows={8}
          fullWidth
          variant="outlined"
          label={config.descriptionFieldLabel[props.lang]}
        />
      </div>
    </ContentContainer>
  )
}

export default IdeaProfile
