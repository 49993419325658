import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const CLICK_SUBMIT_FEEDBACK_BUTTON_ACTION = '[UI] click submit feedback button'

export interface ClickSubmitFeedbackButtonAction extends Action<typeof CLICK_SUBMIT_FEEDBACK_BUTTON_ACTION> {}

export const isClickSubmitFeedbackButtonAction = (x: any): x is ClickSubmitFeedbackButtonAction => 
  isAction(x) && x.type === CLICK_SUBMIT_FEEDBACK_BUTTON_ACTION

export const createClickSubmitFeedbackButtonAction = (): ClickSubmitFeedbackButtonAction => ({
  type: CLICK_SUBMIT_FEEDBACK_BUTTON_ACTION
})
