import produce from 'immer'
import { defaultLanguage } from 'modules/MultiLang'
import { LanguageState } from './LanguageState'
import { isSetLanguageAction } from './actions/SetLanguageAction';

const initialLanguageState: LanguageState = {
  selectedLanguage: defaultLanguage
}

const languageReducer = produce((draft: LanguageState, action: any): void => {
  if (isSetLanguageAction(action)) {
    draft.selectedLanguage = action.payload.selectedLanguage
  }
}, initialLanguageState);

export default languageReducer
