import React from 'react'
import { store } from 'state/store'
import { createLogoClickAction } from './actions/LogoClickAction'
import logo from './logo.svg'

function Logo() {
  return <img onClick={() => store.dispatch(createLogoClickAction())} src={logo} alt="Logo" />
}

export default Logo
