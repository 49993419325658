import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const BACK_BUTTON_CLICK_ACTION = '[UI] back button click'

export interface BackButtonClickAction extends Action<typeof BACK_BUTTON_CLICK_ACTION> {}

export const isBackButtonClickAction = (x: any): x is BackButtonClickAction => 
  isAction(x) && x.type === BACK_BUTTON_CLICK_ACTION

export const createBackButtonClickAction = (): BackButtonClickAction => ({
  type: BACK_BUTTON_CLICK_ACTION
})
