import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const NEXT_VIEW_ACTION = '[MW] next view'

export interface NextViewAction extends Action<typeof NEXT_VIEW_ACTION> {}

export const isNextViewAction = (x: any): x is NextViewAction =>
  isAction(x) && x.type === NEXT_VIEW_ACTION

export const createNextViewAction = (): NextViewAction => ({
  type: NEXT_VIEW_ACTION
})
