import React, { ReactNode } from 'react'
import { store } from 'state/store'
import SwipeableViews from 'react-swipeable-views';
import { createSwipeViewAction } from './actions/SwipeViewAction'

// TODO Sollte das folgende besser ausserhalb der UI definiert sein?

function Content(props: {
  selectedViewIndex: number,
  children?: ReactNode[],
}) {
  return (
    <SwipeableViews
      index={props.selectedViewIndex} 
      onChangeIndex={(index: number) => store.dispatch(createSwipeViewAction(index))} 
      // enableMouseEvents
      resistance
      springConfig={{
        // duration: '0.35s',
        duration: '1.25s',
        // easeFunction: 'cubic-bezier(0.15, 0.3, 0.25, 1)',
        easeFunction: 'cubic-bezier(0.5, 0.3, 0.5, 1)', // https://developer.mozilla.org/en-US/docs/Web/CSS/easing-function
        delay: '0s',
      }}
    >
      {props.children}
    </SwipeableViews>
  )
}

export default Content
