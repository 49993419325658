import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const FEEDBACK_FAILURE_ACTION = '[MW] feedback failure'

export interface FeedbackFailureAction extends Action<typeof FEEDBACK_FAILURE_ACTION> { 
  payload: {
    errorMessage: string
  }
}

export const isFeedbackFailureAction = (x: any): x is FeedbackFailureAction =>
  isAction(x) && x.type === FEEDBACK_FAILURE_ACTION

export const createFeedbackFailureAction = (errorMessage: string): FeedbackFailureAction => ({ 
  type: FEEDBACK_FAILURE_ACTION,
  payload: {
    errorMessage
  }
})
