import React from 'react'
import ReactDOM from 'react-dom'
// import * as serviceWorker from './serviceWorker'
import './index.css'
import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme, ThemeProvider } from '@material-ui/core'
import App from 'components/App'
import { store, AppState } from 'state/store'


const theme = createMuiTheme({
  palette: {
    primary: {
      // main: '#cfe9f5',
      main: '#6FC0E3',
    },
    contrastThreshold: 2,
    tonalOffset: 0.05,
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
})


function renderUI(state: AppState) {
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App state={state} />
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

function render() {
  renderUI(store.getState())
}


store.subscribe(render);
// TODO: Wrap subscription in Observable o. ä.? Vgl. https://redux.js.org/api/store#subscribelistener


// Initial render
render();




// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
