import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const CHANGE_FEEDBACK_CONSENT_ACTION = '[UI] change feedback consent'

export interface ChangeFeedbackConsentAction extends Action<typeof CHANGE_FEEDBACK_CONSENT_ACTION> {}

export const isChangeFeedbackConsentAction = (x: any): x is ChangeFeedbackConsentAction => 
  isAction(x) && x.type === CHANGE_FEEDBACK_CONSENT_ACTION

export const createChangeFeedbackConsentAction = (): ChangeFeedbackConsentAction => ({
  type: CHANGE_FEEDBACK_CONSENT_ACTION
})
