import React from "react";
import { VictoryScatter, VictoryChart, VictoryAxis, VictoryTheme, VictoryArea, VictoryLabel, VictoryLegend, VictoryContainer } from 'victory';

/**
 * Draws a scatter chart with a single data point (value). The dimensions of both axes are from 0 to 100. The background
 * of the chart is divided in four sectors, each with its own color, which can be chosen by applying css using css 
 * variables "--sector-N-color", where N is between 1 and 4.
 * 
 * @param props.value x and y coordinate of the data value to display in the chart, each between 0 and 100
 * @param props.axisLabels labels for the x and y axes
 * @param props.sectorBoundary x and y coordinate of the "sector junction point", each between 0 and 100
 * @param props.sectorNames Names of the sectors in the order top left, top right, bottom left, bottom right.
 *   Used for the legend.
 */
function Chart(props: {
  value: { x: number, y: number },
  axisLabels: { x: string, y: string },
  sectorBoundary: { x: number, y: number },
  sectorNames: {
    topLeft: string,
    topRight: string,
    bottomLeft: string,
    bottomRight: string,
  },
}) {
  const chartData = [props.value]
  const tickValues = [0,10,20,30,40,50,60,70,80,90,100]
  const sectorColors = [1,2,3,4].map(i => `var(--chart-sector-${i}-color)`)
  const itemColor = 'var(--chart-item-color)'
  const {x: bx, y: by} = props.sectorBoundary
  const areas = [
    {
      x: [0, bx],
      y: [by, 100],
    },
    {
      x: [bx, 100],
      y: [by, 100],
    },
    {
      x: [0, bx],
      y: [0, by],
    },
    {
      x: [bx, 100],
      y: [0, by],
    },
  ]
  
  type sectorPosition = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
  const sectorNameOrder: [sectorPosition, sectorPosition, sectorPosition, sectorPosition] = 
    ['topLeft', 'topRight', 'bottomLeft', 'bottomRight']

  return (
    <VictoryChart
      theme={VictoryTheme.material}
      padding={{top: 45, bottom: 45, left: 45, right: 10}}
      containerComponent={
        <VictoryContainer style={{touchAction: 'auto'}} /> // To enable touch interaction on the chart, see https://github.com/FormidableLabs/victory/issues/1037#issuecomment-623048143
      }
    >
      {areas.map((area, i) => (
        <VictoryArea
          key={i}
          data={[
            {x: area.x[0], y0: area.y[0], y: area.y[1]},
            {x: area.x[1], y0: area.y[0], y: area.y[1]},
          ]}
          style={{data: {fill: sectorColors[i]}}}
        />
      ))}
      <VictoryAxis 
        tickValues={tickValues}
        style={{tickLabels: {fontSize: 10}, axisLabel: {fontSize: 10, padding: 30}}}
        label={props.axisLabels.x}
      />
      <VictoryAxis 
        dependentAxis
        tickValues={tickValues}
        style={{tickLabels: {fontSize: 10}, axisLabel: {fontSize: 10, padding: 30}}}
        label={props.axisLabels.y}
      />
      <VictoryScatter
        data={chartData}
        size={5}
        /* labels={({datum}) => `${datum.text}`} */
        style={{
          data: {fill: itemColor, stroke: '#444', strokeWidth: 0.2}, 
          labels: {}
        }}
        symbol="circle"
      />
      <VictoryLegend
        x={45}
        y={5}
        orientation="horizontal"
        data={
          [0,1,2,3].map(i => (
            {symbol: {type: 'square', fill: sectorColors[i]}, name: props.sectorNames[sectorNameOrder[i]]}
          ))
        }
        itemsPerRow={2}
        labelComponent={<VictoryLabel angle={0} />}
        style={{
          labels: {fontSize: 8},
          data: {stroke: '#888', strokeWidth: 0.2}
        }}
        gutter={20}
      />
    </VictoryChart>
  )
}

export default Chart
