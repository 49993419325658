import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const LANGUAGE_TOGGLE_ACTION = '[UI] language toggle'

export interface LanguageToggleAction extends Action<typeof LANGUAGE_TOGGLE_ACTION> {}

export const isLanguageToggleAction = (x: any): x is LanguageToggleAction => 
  isAction(x) && x.type === LANGUAGE_TOGGLE_ACTION

export const createLanguageToggleAction = (): LanguageToggleAction => ({
  type: LANGUAGE_TOGGLE_ACTION
})
