import produce from 'immer'
import { NavigationState } from "./NavigationState"
import { analysisViewIndex } from 'config/AppConfig'
import { didChoicesComeFromURL } from 'state/ideaEvaluation'
import { isSetViewAction } from './actions/SetViewAction'
import { isSetInfoPopupClosedAction } from './actions/SetInfoPopupClosedAction'

const initialNavigationState: NavigationState = didChoicesComeFromURL ? { 
  selectedViewIndex: analysisViewIndex, 
  infoPopupClosed: true
} : {
  selectedViewIndex: 0, 
  infoPopupClosed: false
}

const navigationReducer = produce((draft: NavigationState, action: any): void => {
  if (isSetViewAction(action)) {
    // TODO: Check, if payload is a valid index(?)
    draft.selectedViewIndex = action.payload.selectedViewIndex
  } else if (isSetInfoPopupClosedAction(action)) {
    draft.infoPopupClosed = action.payload.infoPopupClosed
  }
}, initialNavigationState)

export default navigationReducer
