import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const FEEDBACK_SUCCESS_ACTION = '[MW] feedback success'

export interface FeedbackSuccessAction extends Action<typeof FEEDBACK_SUCCESS_ACTION> {}

export const isFeedbackSuccessAction = (x: any): x is FeedbackSuccessAction =>
  isAction(x) && x.type === FEEDBACK_SUCCESS_ACTION
  
export const createFeedbackSuccessAction = (): FeedbackSuccessAction => ({
  type: FEEDBACK_SUCCESS_ACTION
})
