import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const SET_MENU_OPEN_ACTION = '[STATE] set menu open'

export interface SetMenuOpenAction extends Action<typeof SET_MENU_OPEN_ACTION> { 
  payload: {
    isOpen: boolean
  }
}

export const isMenuOpenAction = (x: any): x is SetMenuOpenAction => 
  isAction(x) && x.type === SET_MENU_OPEN_ACTION

export const createSetMenuOpenAction = (isOpen: boolean): SetMenuOpenAction => ({ 
  type: SET_MENU_OPEN_ACTION,
  payload: {
    isOpen
  }
})
