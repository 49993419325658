import { Action } from "redux"
import { isAction } from "modules/ActionTypeGuard"

const CHANGE_FEEDBACK_CHOICE_ACTION = '[UI] change feedback choice'

export interface ChangeFeedbackChoiceAction extends Action<typeof CHANGE_FEEDBACK_CHOICE_ACTION> {
  payload: {
    questionId: number,
    selectedChoiceId: number
  }
}

export const isChangeFeedbackChoiceAction = (x: any): x is ChangeFeedbackChoiceAction =>
  isAction(x) && x.type === CHANGE_FEEDBACK_CHOICE_ACTION

export const createChangeFeedbackChoiceAction = (
  questionId: number,
  selectedChoiceId: number
): ChangeFeedbackChoiceAction => ({
  type: CHANGE_FEEDBACK_CHOICE_ACTION,
  payload: {
    questionId,
    selectedChoiceId,
  },
})
