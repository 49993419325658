import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const SET_IDEA_EVALUATION_CHOICE_ACTION = '[STATE] set idea evaluation choice';

export interface SetIdeaEvaluationChoiceAction extends Action<typeof SET_IDEA_EVALUATION_CHOICE_ACTION> {
  payload: {
    groupId: number,
    questionId: number,
    choice: number,
  }
}

export const isSetIdeaEvaluationChoiceAction = (x: any): x is SetIdeaEvaluationChoiceAction =>
  isAction(x) && x.type === SET_IDEA_EVALUATION_CHOICE_ACTION

export const createSetIdeaEvaluationChoiceAction = (
  groupId: number,
  questionId: number,
  choice: number,
): SetIdeaEvaluationChoiceAction => ({
  type: SET_IDEA_EVALUATION_CHOICE_ACTION,
  payload: {
    groupId,
    questionId,
    choice,
  }
})
