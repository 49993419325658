import React from 'react'
import { store } from 'state/store'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { createCloseInfoPopupAction } from './actions/CloseInfoPopupAction'
import { LanguageCode } from 'modules/MultiLang'
import { infoPopup } from 'config/AppConfig'
import { InfoPopupConfig } from './InfoPopupConfig'

const config: InfoPopupConfig = infoPopup

function InfoPopup(props: {
  lang: LanguageCode,
}) {
  return (
    <Dialog
      open={true}
      onClose={() => store.dispatch(createCloseInfoPopupAction())}
    >
      <DialogTitle>
        {config.heading[props.lang]}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {config.text[props.lang]}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => store.dispatch(createCloseInfoPopupAction())}
        >
          {config.closeButtonLabel[props.lang]}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default InfoPopup
