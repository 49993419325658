import React from 'react'
import { Paper } from '@material-ui/core'
import { LanguageCode } from "modules/MultiLang"
import { EmailTemplateConfig } from './EmailTemplateConfig'
import './EmailTemplate.css'
import { emailTemplate } from 'config/AppConfig'

const config: EmailTemplateConfig = emailTemplate

function EmailTemplate(props: {
  x: number,
  y: number,
  url: string,
  lang: LanguageCode,
}) {
  const { x, y, url, lang } = props
  return (
    <div className="email-template">
      <h3>{config.heading[lang]}</h3>
      <p>{config.intro[lang]}</p>
      <Paper>
        <p>{config.salutation[lang]}</p>
        <p>
          {config.mainContent1[lang]}
          {' ' + y + ' '}
          {config.mainContent2[lang]}
          {' ' + x + ' '}
          {config.mainContent3[lang]}
        </p>
        <p>{config.linkIntro[lang]}</p>
        <p><a href={url}>{url}</a></p>
        <p>{config.concludingParagraph[lang]}</p>
        <p>{config.valediction[lang]}</p>
      </Paper>
    </div>
  )
}

export default EmailTemplate
