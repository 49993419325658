import React from 'react'
import { AppState } from 'state/store'
import Header from 'components/Header'
import NavBar from 'components/NavBar'
import InfoPopup from 'components/InfoPopup'
import './App.css'
import { 
  getIndexOfFirstUnansweredQuestion,
  isIdeaEvaluationCompleted,
  IdeaEvaluationState
} from 'state/ideaEvaluation'
import Content from 'components/Content'
import Feedback from 'components/Feedback'
import IdeaAnalysis from 'components/IdeaAnalysis'
import IdeaProfile from 'components/IdeaProfile'
import { LanguageCode, MultiLangString } from 'modules/MultiLang'
import { FeedbackState } from 'state/feedback'
import IdeaEvaluationQuestion from 'components/IdeaEvaluationQuestion'
import { take } from 'ramda'
import { questionGroups } from 'config/AppConfig'

// TODO Wo sollten diese beiden Typdeklarationen am besten hin?

export interface MultipleChoiceQuestion {
  question: MultiLangString,
  choices: MultiLangString[],
}

export interface MultipleChoiceQuestionGroup {
  title: MultiLangString,
  questions: MultipleChoiceQuestion[],
}



interface Question {
  groupIndex: number,
  questionIndex: number,
}

/**
 * All questions of all groups flattened to a list of questions identified by group and question index.
 */
const questions: Question[] = questionGroups.map(
  (group, groupIndex) => group.questions.map(
    (question, questionIndex) => ({
      groupIndex,
      questionIndex,
    })
  )
).flat()

function renderIdeaEvaluationQuestions(ideaEvaluationState: IdeaEvaluationState, lang: LanguageCode) {

  const indexOfFirstUnansweredQuestion = getIndexOfFirstUnansweredQuestion(ideaEvaluationState)

  const questionsToRender = indexOfFirstUnansweredQuestion === null ? (
    // All questions have to be rendered
    questions
  ) : (
    // Just all answered questions plus the first unanswered one have to be rendered (can also be all questions)
    take(1 + indexOfFirstUnansweredQuestion, questions)
  )

  return questionsToRender.map((question, index) => (
    <IdeaEvaluationQuestion
      questionGroupIndex={question.groupIndex}
      questionIndex={question.questionIndex}
      selectedChoiceIndex={ideaEvaluationState[question.groupIndex][question.questionIndex]}
      lang={lang}
    />
  ))
}

function renderViews(
  ideaEvaluationState: IdeaEvaluationState,
  feedbackState: FeedbackState,
  lang: LanguageCode,
) {
  // These views will always be rendered
  const profileViews = [ <IdeaProfile lang={lang} /> ]
  const evaluationViews = renderIdeaEvaluationQuestions(ideaEvaluationState, lang)

  // The following views will only be rendered if the evaluation has been completed
  const evaluationCompleted = isIdeaEvaluationCompleted(ideaEvaluationState)

  const analysisViews = evaluationCompleted ? (
    [ <IdeaAnalysis evaluationState={ideaEvaluationState} lang={lang} /> ]
  ) : (
    []
  )

  const feedbackViews = evaluationCompleted ? (
    [ <Feedback state={feedbackState} lang={lang} /> ]
  ) : (
    []
  )

  return [profileViews, evaluationViews, analysisViews, feedbackViews].flat()
}

function App(props: {
  state: AppState, // TODO Nur state, der für die UI wichtig ist, verlangen... Gibt es anderen?
}) {
  const state = props.state

  const renderedViews = renderViews(
    state.ideaEvaluation,
    state.feedback,
    state.language.selectedLanguage,
  )

  const maxAllowedViewIndex = renderedViews.length - 1
  // TODO Diese Einschränkung muss auch bei State-Updates geprüft werden. Sollte diesbezüglicher Code also nicht 
  // irgendwo zentral liegen?

  const showInfoPopup = 
    !state.navigation.infoPopupClosed
    && state.navigation.selectedViewIndex === 1 
    && getIndexOfFirstUnansweredQuestion(state.ideaEvaluation) === 0

  return (
    <>
      <Header
        isMenuOpen={state.menu.isOpen}
        lang={state.language.selectedLanguage}
      />
      <Content
        selectedViewIndex={state.navigation.selectedViewIndex}
      >
        {renderedViews}
      </Content>
      <NavBar
        selectedViewIndex={state.navigation.selectedViewIndex}
        maxAllowedViewIndex={maxAllowedViewIndex}
        lang={state.language.selectedLanguage}
      />
      {showInfoPopup && (
        <InfoPopup 
          lang={state.language.selectedLanguage}
        />
      )}
    </>
  )
}

export default App
