import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const NEXT_BUTTON_CLICK_ACTION = '[UI] next button click'

export interface NextButtonClickAction extends Action<typeof NEXT_BUTTON_CLICK_ACTION> {}

export const isNextButtonClickAction = (x: any): x is NextButtonClickAction => 
  isAction(x) && x.type === NEXT_BUTTON_CLICK_ACTION

export const createNextButtonClickAction = (): NextButtonClickAction => ({
  type: NEXT_BUTTON_CLICK_ACTION
})
