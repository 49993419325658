import React from 'react'
import './Header.css'
import Logo from 'components/Logo'
import LanguageToggle from 'components/LanguageToggle'
import MainMenu from 'components/MainMenu'
import { LanguageCode } from 'modules/MultiLang'

function Header(props: {
  isMenuOpen: boolean,
  lang: LanguageCode,
}) {
  return (
    <header>
      <div>
        <Logo />
      </div>
      <div>
        <LanguageToggle
          lang={props.lang}
        />
        <MainMenu 
          isMenuOpen={props.isMenuOpen}
          lang={props.lang}
        />
      </div>
    </header>
  )
}

export default Header
