import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { languageReducer } from 'state/language'
import { navigationReducer } from 'state/navigation'
import { menuReducer } from 'state/menu'
import { ideaEvaluationReducer,  } from 'state/ideaEvaluation'
import { feedbackReducer } from 'state/feedback'
import ideaEvaluationMiddleware from 'middleware/ideaEvaluationMiddleware'
import languageMiddleware from 'middleware/languageMiddleware'
import menuMiddleware from 'middleware/menuMiddleware'
import navigationMiddleware from 'middleware/navigationMiddleware'
import feedbackMiddleware from 'middleware/feedbackMiddleware'

const rootReducer = combineReducers({
  language: languageReducer,
  navigation: navigationReducer,
  menu: menuReducer,
  ideaEvaluation: ideaEvaluationReducer,
  feedback: feedbackReducer
})

export type AppState = ReturnType<typeof rootReducer>

export const store = createStore(
  rootReducer,
  composeWithDevTools( // Use dev tools integration
    applyMiddleware(
      languageMiddleware,
      navigationMiddleware,
      menuMiddleware,
      ideaEvaluationMiddleware,
      feedbackMiddleware
    )
  ) 
)
