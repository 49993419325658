import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const SET_INFO_POPUP_CLOSED_ACTION = '[STATE] set info popup closed'

export interface SetInfoPopupClosedAction extends Action<typeof SET_INFO_POPUP_CLOSED_ACTION> {
  payload: {
    infoPopupClosed: boolean
  }
}

export const isSetInfoPopupClosedAction = (x: any): x is SetInfoPopupClosedAction =>
  isAction(x) && x.type === SET_INFO_POPUP_CLOSED_ACTION

export const createSetInfoPopupClosedAction = (infoPopupClosed: boolean): SetInfoPopupClosedAction => ({
  type: SET_INFO_POPUP_CLOSED_ACTION,
  payload: {
    infoPopupClosed
  }
})
