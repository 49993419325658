type QuestionWithChoices<T> = {
  question: T,
  choices: T[],
}

type QuestionWithoutChoices<T> = {
  question: T,
}

type Question<T> = QuestionWithChoices<T> | QuestionWithoutChoices<T>

function isQuestionWithChoices<T>(question: Question<T>): question is QuestionWithChoices<T> {
  return (question as QuestionWithChoices<T>).choices !== undefined;
}

type QuestionGroupWithChoices<T> = {
  title: T,
  questions: (QuestionWithoutChoices<T> | QuestionWithChoices<T>)[],
  choices: T[],
}

type QuestionGroupWithoutChoices<T> = {
  title: T,
  questions: QuestionWithChoices<T>[],
}

type QuestionGroup<T> = QuestionGroupWithChoices<T> | QuestionGroupWithoutChoices<T>

function isQuestionGroupWithChoices<T>(questionGroup: QuestionGroup<T>): 
  questionGroup is QuestionGroupWithChoices<T> 
{
  return (questionGroup as QuestionGroupWithChoices<T>).choices !== undefined;
}

export type IdeaEvaluationConfig<T> = {
  questionGroups: QuestionGroup<T>[],
}



function toQuestionWithChoices<T>(question: Question<T>, defaultChoices: T[]): QuestionWithChoices<T> {
  if (isQuestionWithChoices(question)) {
    return question
  } else {
    return {
      question: question.question,
      choices: defaultChoices
    }
  }
}

function toQuestionGroupWithoutChoices<T>(questionGroup: QuestionGroup<T>): QuestionGroupWithoutChoices<T> {
  if (isQuestionGroupWithChoices(questionGroup)) {
    // Lasse Choices bei der Group weg, aber nutze sie für die Transformation der Questions als Standard-Choices
    return {
      title: questionGroup.title,
      questions: questionGroup.questions.map(question => toQuestionWithChoices(question, questionGroup.choices))
    }
  } else {
    return questionGroup
  }
}

export function pushChoices<T>(questionGroups: QuestionGroup<T>[]): QuestionGroupWithoutChoices<T>[] {
  return questionGroups.map(toQuestionGroupWithoutChoices)
}
