import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const MENU_BUTTON_CLICK_ACTION = '[UI] menu button click'

export interface MenuButtonClickAction extends Action<typeof MENU_BUTTON_CLICK_ACTION> {}

export const isMenuButtonClickAction = (x: any): x is MenuButtonClickAction => 
  isAction(x) && x.type === MENU_BUTTON_CLICK_ACTION

export const createMenuButtonClickAction = (): MenuButtonClickAction => ({
  type: MENU_BUTTON_CLICK_ACTION
})
