/**
 * Erzeuge ein Array mit Sub-Arrays der in lengths angegebenen Längen und befülle es mit den Werten aus values.
 * 
 * Falls die Summe der Längen größer als die Anzahl der values ist, fehlen die entsprechenden Einträge im Ergebnis. Ist
 * die Summe der Längen kleiner als die Anzahl der values, so werden die überschüssigen values nicht betrachtet.
 * 
 * @param values 
 * @param lengths 
 */
function splitIntoSlices<T>(values: readonly T[], lengths: readonly number[]): T[][] {

  // Ermittle die sich aus den Längen ergebenden Indexgrenzen der Abschnitte im values Array
  const [, sliceBoundaries] = lengths.reduce(
    ([lengthSum, sliceBoundaries], length) => 
      [
        lengthSum + length, 
        sliceBoundaries.concat([[lengthSum, lengthSum + length - 1]])
      ] as [number, Array<[number, number]>],
    // Initial accumulator value:
    [
      0, // Initial length sum
      [] // initial array of slice boundary pairs
    ] as [number, Array<[number, number]>]
  )

  const slices = sliceBoundaries.map(
    ([startIndex, endIndex]: [number, number]) => values.slice(startIndex, endIndex + 1)
  );

  return slices
}

export default splitIntoSlices
