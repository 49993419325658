import { Action } from "redux"
import { isAction } from 'modules/ActionTypeGuard'

const SET_FEEDBACK_SENT_ACTION = '[STATE] set feedback sent'

export interface SetFeedbackSentAction extends Action<typeof SET_FEEDBACK_SENT_ACTION> {
  payload: {
    sent: boolean
  }
}

export const isSetFeedbackSentAction = (x: any): x is SetFeedbackSentAction =>
  isAction(x) && x.type === SET_FEEDBACK_SENT_ACTION

export const createSetFeedbackSentAction = (sent: boolean): SetFeedbackSentAction => ({
  type: SET_FEEDBACK_SENT_ACTION,
  payload: {
    sent
  }
})
